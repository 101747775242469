import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { defaults } from "chart.js";
import { Line } from "react-chartjs-2";
import { monthsDict } from "../utils/colorsPallete";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
defaults.font.family = "Neue Montreal";

export default function MarketValueChart(props) {
  let COPLocale = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumFractionDigits: 0,
  });
  const options = {
    defaultFontFamily: "Neue Montreal",
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    maintainAspectRatio: false,

    annotation: {
      annotations: [
        {
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: 5,
          borderColor: "rgb(75, 192, 192)",
          borderWidth: 4,
          label: {
            enabled: false,
            content: "Test label",
          },
        },
      ],
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: props.year,
        font: {
          size: 24,
          weight: "bold",
          family: defaults.font.family,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.datasetIndex === 0) {
              label += COPLocale.format(context.parsed.y.toFixed(0));
            } else {
              label += (context.parsed.y * 100).toFixed(2) + "%";
            }
            return label;
          },
          title: function (context) {
            let title = monthsDict[context[0].label];
            return title;
          },
        },
      },
      legend: {
        position: "bottom",
        align: "middle",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        ticks: {
          callback: function (label, index, labels) {
            return (label / 1000000).toFixed(1) + "M";
          },
        },
        min: Math.min.apply(
          Math,
          props.marketValueSerie.map(function (o) {
            return o == null ? Infinity : o;
          })
        ),
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: function (label, index, labels) {
            return (label * 100).toFixed(2) + "%";
          },
        },
        min: Math.min.apply(
          Math,
          props.ownershipSerie.map(function (o) {
            return o == null ? Infinity : o;
          })
        ),
        max:
          Math.max.apply(Math, props.ownershipSerie) +
          Math.max.apply(Math, props.ownershipSerie) * 0.05,
      },
    },
  };
  const labels = Array.from({ length: 12 }, (_, i) => i + 1);

  const data = {
    labels,
    datasets: [
      {
        label: "Valor propiedad",
        data: props.marketValueSerie,
        borderColor: "rgb(40, 5, 65)",
        backgroundColor: "rgba(40, 5, 65, 1, 0.5)",
        yAxisID: "y",
      },
      {
        label: "Porcentaje adquirido",
        data: props.ownershipSerie,
        borderColor: "rgb(0, 193, 175)",
        backgroundColor: "rgba(0, 193, 175, 0.5)",
        yAxisID: "y1",
      },
    ],
  };

  return (
    <div className="series-chart">
      <button
        className="chart-control"
        disabled={!props.enabledArrows["left_arrow"]}
        onClick={props.decreaseYear}
      >
        <div className="icon"></div>
      </button>
      <div className="chart-container">
        <Line options={options} data={data} />
      </div>
      <button
        className="chart-control"
        disabled={!props.enabledArrows["right_arrow"]}
        onClick={props.increaseYear}
      >
        <div className="icon"></div>
      </button>
    </div>
  );
}
