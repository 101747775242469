import axios from "axios";
import React from "react";

export class ProfileInteractions extends React.Component {
	constructor(props) {
		super(props);
		this.tenantsEndpoint = "/tenants_api/tenants";
		this.handleSentData = props.handleSentData;
		this.showPasswordError = props.showPasswordError;
	}

	async updateProfile(investorData) {
		let success = false;
		const updatedInvestorData = await axios
			.post(`${this.tenantsEndpoint}/update_profile?password=${encodeURIComponent(investorData.current_password)}`, investorData)
			.then((response) => {
				localStorage.setItem("profile", JSON.stringify(response.data));
				this.handleSentData();
				success = true;
				return response.data;
			}).catch(function (error) {
				if (error.response) {
					// Request made and server responded
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
			});
		if (!success) {
			this.showPasswordError()
		}
		return updatedInvestorData;
	}
	async restorePassword(investorData) {
		const updatedInvestorData = await axios
			.put(`${this.tenantsEndpoint}/restore_password?registration_user=${investorData.registration_user}&new_password=${encodeURIComponent(investorData.password)}`)
			.then((response) => {
				console.log(response.data);
				localStorage.setItem("profile", JSON.stringify(response.data));
				investorData.handleRestoredPassword();
				return response.data;
			}).catch(function (error) {
				if (error.response) {
					// Request made and server responded
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
			});
		return updatedInvestorData;
	}
}
