import Tooltip from '@mui/material/Tooltip';
import React from 'react';

export function FinancialCard(props) {
  const moneyFormatter = Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    maximumFractionDigits: 0,
  });
  const percentageFormatter = Intl.NumberFormat('es-CO', {
    style: 'percent',
    maximumFractionDigits: 2,
  });
  var value = props.value;

  if (props.type === 'money') {
    value = moneyFormatter.format(value);
  } else if (props.type === 'percentage') {
    value = percentageFormatter.format(value);
  } else if (props.type === 'multiple_percentage') {
    value =
      percentageFormatter.format(value) +
      ' / ' +
      percentageFormatter.format(value * 12);
  } else if (props.type === 'months') {
    value = value + ' meses';
  }
  return (
    <>
      <div className='info-financial-card'>
        <a className='title p4'
        href={props.href?props.href:null}
        id={props.title==="Última inflación DANE"?"dane-url":null}
        target="_blank">
          {props.title}
          {props.tooltip && (
            <Tooltip
              title={props.tooltip}
              placement='right'
              className='card-tooltip'>
              <div className='info'>
                <div className='icon'></div>
              </div>
            </Tooltip>
          )}
        </a>
        <p className='value p1 bold dark-purple m-0'>{value}</p>
      </div>
    </>
  );
}
