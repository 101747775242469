import React, { useState, useContext, useEffect } from 'react';
import { StoreContext } from '../utils/store';
import axios from 'axios';

export function ProfilePicture() {
  const uploadFileEndpoint = '/tenants_api/tenants/upload_profile_image';
  const { profile } = useContext(StoreContext);
  const [imageSrc, setImageSrc] = useState(profile['profile_img_src'] || null);
  const [newImage, setNewImage] = useState(null);

  const handleInputChange = (event) => {
    setImageSrc(URL.createObjectURL(event.target.files[0]));
    setNewImage(event.target.files[0])
  };
  useEffect(() => {
    async function updateImage() {
      const formData = new FormData()
      const contentType = "image/jpg"
      const APIResponse = await axios.get(
        uploadFileEndpoint+"?tenant_id="+profile["id"]+"&content_type="+contentType
        );
      Object.entries(APIResponse.data.response.fields).forEach(([k, v]) => {
        formData.append(k, v);
      });
      formData.append('file', newImage)
      await axios.post(
        APIResponse.data.response.url,
        formData,
      {
        headers: {'Content-Type': 'multipart/form-data'},
      }
      )
      }
      if (newImage) {
        updateImage();
      }

    }, [newImage]);

  return (
    <div className='profile-pic-container'>
      <label htmlFor='file-input'>
        {imageSrc ? (
          <img
            src={imageSrc}
            alt='Profile'
          />
        ) : (
          <p className='p5'>Subir Foto</p>
        )}
      </label>
      <input
        id='file-input'
        type='file'
        accept='image/*'
        onChange={handleInputChange}
      />
    </div>
  );
}
