import React, { useState, useEffect } from 'react';

const DonutChart = (props) => {
  const [angle, setAngle] = useState(0);
  const [centerText, setCenterText] = useState('');

  const dimensions = {
    width: 200,
    height: 200,
    outerRadius: 60,
    innerRadius: 60,
    centerRadius: 40,
  };

  useEffect(() => {
    const newAngle = (props.percentFilled / 100) * 360;
    setAngle(newAngle - 1);
    setCenterText(`${props.textContent}%`);
  }, [props.percentFilled]);
  const startAngle = props.startAngle;
  const endAngle = startAngle + angle;

  const outerCircle = {
    cx: dimensions.width / 2,
    cy: dimensions.height / 2,
    r: dimensions.outerRadius,
  };

  const innerCircle = {
    cx: dimensions.width / 2,
    cy: dimensions.height / 2,
    r: dimensions.innerRadius,
  };

  const centerCircle = {
    cx: dimensions.width / 2,
    cy: dimensions.height / 2,
    r: dimensions.centerRadius,
  };

  const startPoint = {
    x: outerCircle.cx + outerCircle.r * Math.cos((startAngle * Math.PI) / 180),
    y: outerCircle.cy + outerCircle.r * Math.sin((startAngle * Math.PI) / 180),
  };

  const endPoint = {
    x: outerCircle.cx + outerCircle.r * Math.cos((endAngle * Math.PI) / 180),
    y: outerCircle.cy + outerCircle.r * Math.sin((endAngle * Math.PI) / 180),
  };

  const largeArcFlag = angle > 180 ? 1 : 0;

  const innerStartPoint = {
  x:
    innerCircle.cx + innerCircle.r * Math.cos((endAngle * Math.PI) / 180),
  y:
    innerCircle.cy + innerCircle.r * Math.sin((endAngle * Math.PI) / 180),
};  
  const innerEndPoint = {
  x:
    innerCircle.cx + innerCircle.r * Math.cos((startAngle * Math.PI) / 180),
  y:
    innerCircle.cy + innerCircle.r * Math.sin((startAngle * Math.PI) / 180),
};
  const pathData = `M ${startPoint.x} ${startPoint.y}
  A ${outerCircle.r} ${outerCircle.r} 0 ${largeArcFlag} 1 ${endPoint.x} ${endPoint.y}
  L ${innerStartPoint.x} ${innerStartPoint.y}
  A ${innerCircle.r} ${innerCircle.r} 0 ${largeArcFlag} 0 ${innerEndPoint.x} ${innerEndPoint.y}
  Z`;
  return (
    <svg viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}>
      <circle
        {...outerCircle}
        fill='none'
        stroke='#E6E6E6'
        strokeWidth='20'
      />
      <path
        d={pathData}
        fill={props.filledColor}
        stroke={props.filledColor}
        strokeWidth='20'
        strokeDasharray={`${angle} ${360 - angle}`}
     />
      <circle
        {...centerCircle}
        fill='white'
      />
      <text
        x={dimensions.width / 2}
        y={dimensions.height / 2}
        textAnchor='middle'
        alignmentBaseline='middle'>
        {centerText}
      </text>
    </svg>
  );
};

export default DonutChart;

