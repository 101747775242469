import React from 'react';
import { Input } from '../../../Components/Input';

export function Credentials(props) {
  const investorData = props.investorData;
  const handleInvestorData = props.handleInvestorData;
  const inputErrors = props.inputErrors;
  const errorMessages = props.errorMessages;

  return (
    <div className='profile-content'>
      <div className='profile-input-container'>
        <Input
          inputLabel='Email'
          inputType='mail'
          inputKey={'email'}
          disabled={true}
          inputValue={investorData['email']}
          formData={investorData}
          handleFormData={handleInvestorData}
        />
      </div>
      {inputErrors.includes('email') && <p>{errorMessages['email']}</p>}
      <div className='profile-input-container'>
        <Input
          inputLabel='Contraseña actual'
          inputType='password'
          inputKey={'current_password'}
          formData={investorData}
          handleFormData={handleInvestorData}
        />
      </div>
      <div className='profile-input-container'>
        <Input
          inputLabel='Nueva contraseña'
          inputType='password'
          inputKey={'password'}
          formData={investorData}
          handleFormData={handleInvestorData}
        />
      </div>
      <div className='profile-input-container'>
        <Input
          inputLabel='Confirmar contraseña'
          inputType='password'
          inputKey={'confirm_password'}
          formData={investorData}
          handleFormData={handleInvestorData}
        />
        {inputErrors.includes('password') && <p>{errorMessages['password']}</p>}
      </div>
    </div >
  );
}
