import React from "react";
import { Col, Row, Container } from "react-bootstrap";

export function Tyc() {
  return (
    <>
      <Container fluid>
        <Col xs={12} md={12}>
          <h3 className="bold  text-center mt-5 fs-sm-4 fs-md-3">
            Nuestros Términos y Condiciones
          </h3>
          <Row className="justify-content-center">
            <Col xs={11} md={8} className="text-justify my-5">
              <p>
                Estos términos y condiciones de uso (los “Términos y
                Condiciones”) tienen como finalidad regular y establecer las
                condiciones por el acceso a los Servicios (como se define a
                continuación) puestos a su disposición (“Usted” y/o el
                “Usuario”) a través de la página TOPERTY.CO (la “Página Web”),
                la cual es propiedad de Toperty S.A.S., sociedad constituida
                conforme a las leyes de la República de Colombia, identificada
                con NIT No. (901.621.275-1) y domiciliada en la ciudad Bogotá
                (“Toperty”).
              </p>
              <p>
                Al ingresar y hacer uso de los Servicios ofrecidos en la Página
                Web, el Usuario entiende y acepta en su totalidad los Términos y
                Condiciones que a continuación se exponen.
              </p>
              <h5 className="bold">1. Objeto</h5>
              <p>
                El presente acuerdo establece los Términos y Condiciones en los
                que el Usuario podrá acceder a la Página Web para utilizar
                cualquiera de los servicios ofrecidos por Toperty. El ingreso y
                uso de la Página Web por parte del Usuario es una declaración
                formal e inequívoca sobre la aceptación a todos los Términos y
                Condiciones incluidos aquí. Por eso, sugerimos que el Usuario
                lea cuidadosamente los Términos y Condiciones antes de hacer uso
                de la Página Web y de sus servicios.
              </p>
              <p>
                Toperty se reserva el derecho a modificar los Términos y
                Condiciones del presente documento. Dichas modificaciones
                surtirán efecto a partir de su publicación en la Página Web.
              </p>
              <h5 className="bold">
                2. Definición y alcance de los Servicios.
              </h5>
              <p>
                Por medio de la Página Web, Toperty permite al Usuario el acceso
                a su Página Web para facilitar que el Usuario solicite servicios
                de cotización de arriendo y simulación financiera de compra de
                bienes inmuebles con tiempos y procesos simplificados (los
                “Servicios”). Los Servicios ofrecidos al Usuario constan de los
                siguientes alcances, a saber: i) revisión de la situación
                financiera del Usuario y análisis de viabilidad para adquirir
                vivienda mediante el modelo propuesto por Toperty, siguiendo los
                acuerdos contractuales que las partes suscriban; ii) publicación
                de inmuebles disponibles a la venta que cumplen con los
                criterios de Toperty; iii) recibo de visita y análisis de
                viabilidad de vinculación al usuario; iv) negociación y
                suscripción de los acuerdos contractuales para el arriendo y
                adquisición de vivienda; y v) demás actividades relacionadas.
              </p>
              <p>
                Los Servicios estarán disponibles en Bogotá y en el lugar en el
                que el Usuario pretenda adquirir la vivienda o el lugar en el
                que Toperty ofrezca opciones de vivienda. Toperty podrá, bajo su
                propio criterio, ampliar la cobertura de los Servicios a otras
                ciudades.
              </p>
              <p>
                El Usuario de la Página Web no podrá hacer uso de la misma con
                fines ilícitos, que lesionen derechos e intereses de Toperty,
                terceros, o que de cualquier forma puedan perjudicar,
                inutilizar, sobrecargar, deteriorar o impedir la normal
                utilización de este sitio, los equipos informáticos o los
                documentos, archivos y toda clase de contenidos almacenados en
                cualquier equipo informático de Toperty. La infracción de las
                condiciones de uso de la Página Web, por parte de un Usuario,
                implicará la cancelación de su cuenta y la eliminación de toda
                la información relacionada a dicho Usuario. Toperty hace expresa
                reserva de las acciones legales que estime pertinentes para los
                casos de infracción el presente contrato o de la ley.
              </p>
              <h5 className="bold">
                3. Términos y condiciones: rompiendo las barreras de acceso a la
                vivienda
              </h5>
              <p>
                Gracias por tu interés en Toperty y en querer hacer negocios con
                nosotros, pues estamos convencidos de que el acceso a una
                vivienda es un derecho fundamental y prioritario. Para los
                efectos de estos términos y condiciones, la sociedad TOPERTY se
                denominará “Toperty” y tú, como cliente interesado en adquirir
                una vivienda bajo arriendo y potencialmente comprarla, te
                denominarás como el “CLIENTE”. A continuación, se presentan los
                términos y condiciones aplicables a nuestra servicio que busca:
                Romper las barreras de acceso a la vivienda propia:
              </p>
              <h5 className="bold">3.1. Dinámica de Negociación.</h5>
              <p>
                La negociación para el arriendo y compraventa de la vivienda se
                realiza de manera voluntaria y libre entre Toperty y el CLIENTE.
                Cada parte está en libertad de aceptar o no los términos
                particulares del negocio y suscribir los documentos legales
                vinculantes. De no aceptarse los términos propuestos, no se
                llevará a cabo el negocio. Por lo tanto, cada parte de reserva
                el derecho de realizar o no el negocio.
              </p>
              <p>
                Hasta tanto no se suscriban los documentos legales de carácter
                vinculante, tanto el CLIENTE como Toperty podrán retractarse en
                cualquier momento, sin previo aviso y sin ninguna consecuencia
                legal ni contraprestación a cargo. Igualmente, cada parte es
                libre y está facultada para modificar las propuestas de negocio,
                dentro de las que se incluye el precio, canon de arrendamiento,
                duración, la forma de pago, obligaciones, entre otras. Una vez
                firmados los documentos legales, Toperty y el CLIENTE se
                ajustarán a los términos y condiciones que allí se indiquen en
                los eventos de retracto, terminación y/o incumplimiento,
                sanciones, entre otros.
              </p>
              <p>
                Dentro del proceso de negociación, el CLIENTE podrá solicitar y
                proponer modificaciones o ajustes a los documentos legales. Sin
                embargo, al ser este un negocio que requiere el acuerdo de
                voluntades, está a entera discreción y facultad de Toperty
                aceptar o rechazar dichas propuestas o sugerencias. La no
                aceptación de las propuestas o no llevar a cabo el negocio no
                implicará un incumplimiento de Toperty ni Toperty deberá
                manifestar las razones o justificaciones para su decisión.
                Toperty tiene absoluta discreción para no aceptar y suscribir
                los acuerdos contractuales con el CLIENTE.
              </p>
              <p>
                Es responsabilidad exclusiva del CLIENTE leer la totalidad de
                los documentos sujetos a su revisión y firma, y la de conocer
                sus efectos y consecuencias. Toperty no se hace responsable de
                que el CLIENTE no haga la lectura de los documentos o decida no
                obtener asesoría legal, financiera, comercial, tributaria o de
                cualquier naturaleza para llevar a cabo el negocio. En todo
                momento el CLIENTE podrá vincular y consultar con un asesor para
                que lo acompañe en el proceso de arriendo, compra y venta. Se
                aclara que Toperty en ningún caso será responsable por la
                calidad de la asesoría que se le preste al CLIENTE, el asesor
                que el CLIENTE contacte ni por el pago de los honorarios que
                estos causen. Es responsabilidad exclusiva del CLIENTE la
                decisión de acudir o no a un asesor y el contenido y/o
                pertinencia de la asesoría que dicho asesor le preste.
              </p>
              <h5 className="bold">3.2. Rol de Toperty.</h5>
              <p>
                Toperty en los procesos de arrendamiento y adquisición de
                inmuebles actúa en su calidad de parte interesada que se somete
                a los documentos legales vinculantes que celebre con el CLIENTE.
                De tal manera, Toperty no actúa como un asesor comercial, fiscal
                o financiero del CLIENTE para la toma de decisión de realizar o
                no el negocio.
              </p>
              <h5 className="bold">
                3.4. Estructura de arriendo y compraventa
              </h5>
              <p>
                Teniendo en cuenta el modelo de negocio y el producto que
                solicite el CLIENTE y el tipo de financiación con el que Toperty
                adquiera los inmuebles, los términos y naturaleza de contratos
                podrán variar. Por lo tanto, no necesariamente se suscribirán
                contratos de arriendo o promesas de compraventa de inmuebles
                pudiendo celebrarse otros documentos nominados o innominados de
                otro tipo pero que tengan efectos similares para llegar al
                resultado esperado, siendo esta que el CLIENTE arriende el
                inmueble y se establezca un mecanismo para ir pagando parte del
                precio de la vivienda.
              </p>
              <p>
                En cualquier momento y hasta que se firmen los documentos
                legales vinculantes, el CLIENTE podrá solicitar aclaraciones
                durante el proceso de negociación y en caso de no estar de
                acuerdo, abstenerse de celebrar el negocio.
              </p>
              <h5 className="bold">3.4.1. Precios, Pagos y Forma de Pago</h5>
              <ol type="I">
                <li>
                  <p>
                    El canon de arrendamiento y precio de venta ofrecido por
                    Toperty podrá variar durante el periodo de negociación
                    teniendo en cuenta, entre otras, las condiciones
                    particulares del CLIENTE y del inmueble, el estudio de
                    viabilidad financiera, la capacidad de pago del CLIENTE, de
                    la zona donde se ubica el inmueble y las condiciones
                    particulares del negocio que se acuerden entre el CLIENTE y
                    Toperty, entre otros. Por lo tanto, los precios ofrecidos
                    antes de la firma del contrato de arriendo y de la promesa
                    de venta (en caso de aplicar) no se consideran como precios
                    irrevocables o en firme. Ninguno de los documentos
                    preparatorios, en particular la oferta inicial o la oferta
                    en línea, presentados por Toperty antes de la firma de los
                    acuerdos contractuales establecen precios o condiciones
                    definitivos. Los términos y condiciones sólo quedarán en
                    firme con la firma del contrato (o los contratos) legal
                    vinculante equivalente.
                  </p>
                </li>
                <li>
                  <p>
                    Los pagos para los Servicios se realizarán conforme se
                    indique en el contrato de arriendo, la promesa de
                    compraventa y cualquier otro documento. En todo caso,
                    cualquier pago se realizará, además del cumplimiento de las
                    condiciones que se establezcan en los documentos
                    contractuales, siempre que: (i) se hayan suscrito por parte
                    del CLIENTE la totalidad de los documentos legales
                    vinculantes para llevar a cabo el negocio (ii) el CLIENTE
                    haya aportado la totalidad de la documentación requerida
                    para realizar los pagos indicados en los acuerdos
                    contractuales o documento equivalente, (iii) el CLIENTE no
                    haya omitido información personal, financiera, crediticia o
                    de condiciones del inmueble que conozca previamente (tales
                    como fallas estructurales, cuotas extraordinarias no
                    informadas, declaraciones realizadas en la promesa que no
                    corresponden con la realidad, arrendatarios, comodatarios,
                    invasores, poseedores o tenedores no declarados, condiciones
                    legales del inmueble, omisión de información en el reporte
                    de su estado civil, entre otras), (iv) el CLIENTE no haya
                    incumplido ninguna de sus obligaciones pactadas en los
                    acuerdos contractuales firmados con Toperty, incluyendo la
                    entrega de los documentos requeridos por Toperty, y (v) las
                    condiciones del CLIENTE o del inmueble no hubieran variado o
                    no hubieren cambiado su condición o nivel de riesgo para la
                    realización del negocio durante todo el proceso de
                    negociación hasta la fecha de perfeccionamiento de la
                    operación (sean o no de conocimiento del CLIENTE).
                  </p>
                </li>
                <li>
                  <p>
                    Los pagos se entienden realizados con el comprobante de
                    envío de la transferencia y demás documentos soporte que
                    Toperty provea. Los tiempos de canje o que se acredite el
                    pago en la cuenta del cliente dependerá de los tiempos de
                    respuesta de la entidad financiera en donde el CLIENTE tenga
                    la cuenta bancaria.
                  </p>
                </li>
              </ol>
              <h5 className="bold">
                3.4.2. Costos y pagos que asume Toperty y costos y gastos
                excluidos
              </h5>
              <ol type="I">
                <li>
                  <p>
                    Como regla general, los costos y gastos notariales, registro
                    y tributos serán pagados por la parte que está obligada por
                    la legislación.
                  </p>
                </li>
                <li>
                  <p>
                    Toperty en ningún caso asumirá los costos por asesoría
                    legal, tributaria, comercial, financiera o de cualquier
                    naturaleza a los que acuda el CLIENTE para la celebración
                    del negocio.
                  </p>
                </li>
                <li>
                  <p>
                    Toperty no pagará comisiones o remuneraciones por corretaje
                    salvo expresa y previamente acordado por escrito.
                  </p>
                </li>
                <li>
                  <p>
                    Toperty solo incurrirá en las cargas expresamente indicadas
                    en los acuerdos contractuales suscritos con el CLIENTE y
                    terceros.
                  </p>
                </li>
              </ol>
              <h5 className="bold">
                4. Derechos de Propiedad y Derechos de Propiedad Intelectual de
                Toperty y de terceros.
              </h5>
              <ol type="I">
                <li>
                  <p>
                    Todos los elementos y la información que se encuentra en la
                    Página web, incluyendo textos, procesos, métodos, secretos
                    empresariales, know-how, técnicas, formatos, imágenes,
                    música, marcas, logotipos, enseñas, nombres comerciales,
                    sonidos, gráficos, videos, animación y demás materiales y
                    herramientas susceptibles de protección mediante propiedad
                    intelectual, son y seguirán siendo propiedad de su
                    respectivo titular, sea éste Toperty; sus contratistas,
                    licenciantes, cedentes y, en general, cualquier tercero.
                  </p>
                </li>
                <li>
                  <p>
                    Cualquier uso no-autorizado de los elementos y la
                    información que se encuentra en la Página Web, que infrinja
                    o potencialmente infrinja los derechos de propiedad y de
                    propiedad intelectual de Toperty; sus contratistas,
                    licenciantes, cedentes y, en general, cualquier tercero,
                    podrá implicar el inicio de las acciones legales
                    correspondientes por parte de los titulares de tales
                    derechos.
                  </p>
                </li>
                <li>
                  <p>
                    El acceso y uso de la Página Web no implica de manera alguna
                    la concesión o negación de cualquier licencia, concesión o
                    derecho de uso sobre cualesquiera textos, procesos, métodos,
                    secretos empresariales, know-how, técnicas, formatos,
                    imágenes, música, marcas, logotipos, enseñas, nombres
                    comerciales, sonidos, gráficos, videos, animación y demás
                    materiales y herramientas susceptibles de protección
                    mediante propiedad intelectual.
                  </p>
                </li>
                <li>
                  <p>
                    Está prohibida la creación de páginas web, portales de
                    internet, plataformas, negocios, documentos electrónicos o
                    programas de computador o aplicaciones informáticas de
                    cualquier tipo que contengan hipervínculos o marcas que
                    redirijan al navegante a cualquier parte de la Página Web.
                  </p>
                </li>
                <li>
                  <p>
                    Se excluye expresamente cualquier tipo de responsabilidad
                    que el Usuario le pudiera atribuir a Toperty como
                    consecuencia de la infracción de sus derechos de propiedad y
                    de propiedad intelectual por parte de otros usuarios o de
                    terceros.
                  </p>
                </li>
                <li>
                  <p>
                    Toperty no se hace responsable por los asuntos de propiedad
                    intelectual derivados de los contenidos provenientes del
                    Usuario o de terceros sobre sus productos o servicios.
                  </p>
                </li>
              </ol>
              <h5 className="bold">
                5. Derechos Propiedad y Derechos de Propiedad Intelectual de
                Usuarios.
              </h5>
              <ol type="I">
                <li>
                  <p>
                    Mediante la aceptación de los presentes Términos y
                    Condiciones, Toperty no adquirirá derechos de propiedad
                    intelectual en y sobre los materiales del Usuario. Sin
                    embargo, a fin de la operación de la Página Web, mediante la
                    aceptación de estos Términos y Condiciones, Usted otorga una
                    licencia exclusiva, gratuita, irrevocable, transferible,
                    sub-licenciable y sin limitación territorial o temporal
                    alguna, en relación con todos los derechos, títulos e
                    intereses en y sobre los contenidos relativos a su inmueble
                    (los “Contenidos”), para (i) usar, editar, modificar,
                    realizar derivaciones, reproducir, almacenar, exhibir,
                    comunicar públicamente, transmitir, radiodifundir, copiar,
                    sincronizar, transcodificar, comercializar, promocionar,
                    distribuir, vender y, en general, realizar su explotación
                    comercial; (ii) iniciar y adelantar cualesquiera acciones
                    que sean necesarias para realizar el apoyo, promoción,
                    gestión, ejecución y seguimiento a listados inmobiliarios;
                    (iii) permitir al Usuario descargar, transmitir,
                    radiodifundir; reproducir, exhibir, comunicar públicamente,
                    sincronizar, distribuir y utilizar los Contenidos, y (iv)
                    usar y publicar, y permitir que otros usen y publiquen, los
                    Contenidos y sus datos personales y los de las personas
                    naturales vinculadas a Usted.
                  </p>
                </li>
                <li>
                  <p>
                    A través de la aceptación de los presentes Términos y
                    Condiciones, Usted otorga una licencia no-exclusiva y
                    gratuita para acceder a los Contenidos mediante la Página
                    Web.
                  </p>
                </li>
                <li>
                  <p>
                    Asimismo, a través de estos Términos y Condiciones Usted
                    declara, representa y garantiza que los Contenidos no
                    utilizarán ni incorporarán creaciones susceptibles de
                    protección mediante propiedad intelectual de terceros, a no
                    ser que exista autorización del respectivo titular para
                    dicha los usos y para la licencia que aquí se prevén, o que
                    dicha autorización no sea necesaria. En cualquier caso,
                    Usted será responsable de realizar cualquier pago o
                    contraprestación que sea necesaria para los mencionados
                    usos.
                  </p>
                </li>
                <li>
                  <p>
                    El uso y/o explotación de los Contenidos por parte de
                    Toperty o de los usuarios en los términos que aquí se prevén
                    no infringirán derechos de terceros, incluyendo, pero sin
                    limitarse a, derechos de propiedad, derechos de propiedad
                    intelectual, datos personales y derechos de imagen.
                  </p>
                </li>
              </ol>
              <h5 className="bold">
                6. Licencia para el Uso de la Página Web.
              </h5>
              <ol type="I">
                <li>
                  <p>
                    Toperty le concede a Usted una licencia limitada, no
                    exclusiva y revocable para acceder, consultar y descargar
                    los contenidos de esta Página Web necesarios para el uso de
                    esta, siempre y cuando ello sea para la satisfacción de
                    intereses personales de información. Esta licencia no
                    incluye la autorización para la publicación, distribución,
                    cesión, sub-licenciamiento, transferencia, edición, venta,
                    desarrollo de trabajos derivados o cualquier otro uso que no
                    sea estrictamente para satisfacer una necesidad de
                    información personal o académica y/o para el uso de Página
                    Web para los efectos de la Página Web.
                  </p>
                </li>
                <li>
                  <p>
                    En todo caso, los contenidos de la Página Web, en todo o en
                    parte, sea este gráfico o documental, no podrán ser
                    reproducidos en cualquier forma o incorporados en cualquier
                    otro documento, medio o conjunto de datos que pueda ser
                    recuperado posteriormente a su registro, de forma
                    electrónica, mecánica, óptica o cualquier otra, salvo que su
                    fin sea la satisfacción de los intereses personales de
                    información aquí autorizados y/o para el uso de Página Web
                    para los efectos de Página Web.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    Usted no podrá vender, licenciar, alquilar, o darle otro uso
                    o explotar de alguna manera los contenidos de Página Web
                    para uso comercial o que de cualquier manera viole cualquier
                    derecho de un tercero.
                  </p>
                </li>
              </ol>
              <h5 className="bold">7. Portales de Terceros.</h5>
              <ol type="I">
                <li>
                  <p>
                    Los sitios web enlazados o vinculados a la Página Web, no
                    son de propiedad de Toperty, ni son operados, controlados o
                    administrados por Toperty y, por tanto, el Usuario exonera a
                    Toperty de cualquier tipo de responsabilidad sobre sus
                    contenidos, practicas, políticas, seguridad, servicios,
                    disponibilidad, cumplimiento de sus términos y condiciones
                    de uso ni de sus políticas de privacidad.
                  </p>
                </li>
                <li>
                  <p>
                    Cualquier vínculo o enlace que se hace en la Página Web a
                    sitios web de terceros no constituye patrocinio, amparo,
                    protección, defensa, garantía, tutela o respaldo de ninguna
                    clase por parte de Toperty sobre sus contenidos, prácticas,
                    políticas, seguridad, servicios, disponibilidad,
                    cumplimiento de sus términos y condiciones de uso ni de sus
                    políticas de privacidad.
                  </p>
                </li>
                <li>
                  <p>
                    Es su propia responsabilidad el acceso y uso que haga de los
                    sitios web de terceros enlazados o vinculados a la Página
                    Web.
                  </p>
                </li>
                <li>
                  <p>
                    Se excluye expresamente cualquier tipo de responsabilidad
                    que el Usuario le pudiera atribuir a Toperty como
                    consecuencia de daños relacionados con el uso de portales,
                    servicios, productos o contenidos que se encuentren
                    disponibles a través de los sitios web de terceros enlazados
                    o vinculados a la Página Web.{" "}
                  </p>
                </li>
              </ol>
              <h5 className="bold">8. Información y Contenidos de Terceros</h5>
              <ol type="I">
                <li>
                  <p>
                    La Página Web puede reproducir o contener información
                    proveniente de terceros. Tal es el caso, a título de
                    ejemplo, de los contenidos provistos por otros usuarios,
                    proveedores de contenidos e infraestructura y
                    patrocinadores.
                  </p>
                </li>
                <li>
                  <p>
                    Toperty no verificará la veracidad ni la exactitud de la
                    información y los contenidos provistos por terceros. Toperty
                    no garantiza la veracidad y certeza de dicha información y
                    contenidos y se exonera de cualquier tipo de responsabilidad
                    en relación con tal información y contenidos.
                  </p>
                </li>
                <li>
                  <p>
                    Los terceros son los únicos responsables porque la
                    información y los contenidos que suministrados a la Página
                    Web sean veraces y exactos, no infrinjan derechos de
                    terceros y, en general, no infrinjan la ley que les sea
                    aplicable.
                  </p>
                </li>
              </ol>
              <h5 className="bold">9. Datos Personales</h5>
              <p>
                Si usted desea consultar, actualizar y/o rectificar sus datos
                personales de nuestras bases de datos, agradecemos se comunique
                al correo electrónico{" "}
                <a href="mailto:info@toperty.co">info@toperty.co</a> Si decide
                no hacerlo, consideraremos autorizado el tratamiento de sus
                datos personales. Lo anterior, sin perjuicio del derecho que
                asiste al titular de información en cualquier tiempo de
                consultar, conocer, actualizar sus datos personales, o solicitar
                su supresión o rectificación.
              </p>
              <p>
                Para más información por favor consulte la Política de
                <a href="/personal-data">Tratamiento de Datos Personales.</a>
              </p>
              <h5 className="bold">
                10. Registro de datos para acompañamiento personalizado.
              </h5>
              <p>
                Cada persona natural o jurídica que se encuentre interesada en
                arrendar y adquirir un bien inmueble, podrá realizar una
                solicitud de oferta en línea gratuita e ingresar su nombre,
                apellido, correo electrónico y número de celular, así como las
                características y valor de su inmueble a través de la página web
                www.toperty.co.
              </p>
              <p>
                Toperty presume de buena fe que los datos ingresados por el
                usuario en la Página Web son veraces, propios o de tercero a
                quien legalmente represente. Por lo tanto, el Usuario se obliga
                a sólo utilizar la Página Web con los propósitos y fines
                permitidos por estos Términos y Condiciones y por la ley.
              </p>
              <h5 className="bold">11. Régimen de protección al consumidor.</h5>
              <ol type="I">
                <li>
                  <p className="bold">Garantías</p>
                  <p>
                    Cuando por causas imputables al Proveedor los servicios
                    ofrecidos en la Página Web presenten fallas o sean prestados
                    de forma incompleta al Usuario, este se compromete, previa
                    notificación escrita del Usuario de los hechos y fecha de la
                    falla, a prestar nuevamente el servicio de acuerdo con las
                    condiciones inicialmente ofrecidas.
                  </p>
                  <p>
                    Para que opere la garantía de la que trata la presente
                    sección, la notificación hecha por el Usuario deberá
                    efectuarse con no más de dos (2) meses desde la fecha de
                    acontecimiento de los hechos. A partir de esa fecha, se
                    entenderá que el Usuario se encuentra conforme con las
                    prestación y calidad de los servicios.
                  </p>
                  <p>
                    Toperty se exonerará de la responsabilidad que se deriva de
                    la garantía, cuando demuestre que el defecto proviene de:
                  </p>
                  <ol>
                    <li>
                      <p>Fuerza mayor o caso fortuito.</p>
                    </li>
                    <li>
                      <p>El hecho de un tercero.</p>
                    </li>
                    <li>
                      <p>El uso indebido del bien por parte del Usuario.</p>
                    </li>
                    <li>
                      <p>
                        Que el Usuario no atendió las instrucciones de
                        instalación, uso o mantenimiento.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p className="bold">Derecho de retracto. </p>
                  <p>
                    Al ser los servicios ofrecidos en la Página Web prestados
                    mediante métodos no convencionales, Toperty otorga al
                    Usuario el derecho de retracto. El Usuario que solicite la
                    prestación de los servicios de Toperty tendrá cinco (5) días
                    hábiles contados a partir de la solicitud de la oferta en
                    línea para retractarse de su decisión de adquirir los
                    servicios, siempre que el servicio que pretenda deshacer no
                    haya empezado a ejecutarse.{" "}
                  </p>
                  <p>
                    Verificado los supuestos para el ejercicio del derecho de
                    retracto, Toperty deberá, dentro de los quince (15) días
                    hábiles siguientes a la solicitud de retracto, eliminar la
                    información y solicitud realizada por el Usuario.
                  </p>
                </li>
                <li>
                  <p className="bold">Mayores de Edad.</p>
                  <p>
                    Los servicios y productos ofrecidos por Toperty están
                    destinados para mayores de edad. En caso tal que un menor de
                    edad acceda a los servicios y productos ofrecidos por
                    Toperty, este reconoce que cuenta con la autorización
                    expresa de sus padres para realizar la transacción.
                  </p>
                </li>
                <li>
                  <p className="bold">Reversión del pago.</p>
                  <p>
                    Cuando las ventas se realicen mediante mecanismos de
                    comercio electrónico, tales como Internet, PSE y/o call
                    center y/o cualquier otro mecanismo de televenta o tienda
                    virtual, y se haya utilizado para realizar el pago una
                    tarjeta de crédito, débito o cualquier otro instrumento de
                    pago electrónico, Toperty deberá reversar los pagos que
                    solicite el Usuario cuando sea objeto de fraude, o
                    corresponda a una operación no solicitada, o las demás
                    dispuestas en las Ley 1480 de 2011.{" "}
                  </p>
                  <p>
                    Para que proceda la reversión del pago, dentro los cinco (5)
                    días hábiles siguientes a la fecha en que el consumidor tuvo
                    noticia de la operación fraudulenta el Usuario deberá
                    presentar una queja ante Toperty y devolver el producto,
                    cuando sea procedente, y notificar de la reclamación al
                    emisor del instrumento de pago electrónico utilizado para
                    realizar la compra, el cual, en conjunto con los demás
                    participantes del proceso de pago, procederán a reversar la
                    transacción al comprador.
                  </p>
                  <p>
                    En el evento que existiere controversia entre Toperty y el
                    Usuario derivada de una queja y esta fuere resuelta por
                    autoridad judicial o administrativa a favor de Toperty, el
                    emisor del instrumento de pago, en conjunto con los demás
                    participantes del proceso de pago, una vez haya sido
                    notificado de la decisión, y siempre que ello fuere posible,
                    cargará definitivamente la transacción reclamada al depósito
                    bancario o instrumento de pago correspondiente o la debitará
                    de la cuenta corriente o de ahorros del consumidor, y el
                    dinero será puesto a disposición de Toperty. De no existir
                    fondos suficientes o no resultar posible realizar lo
                    anterior por cualquier otro motivo, los participantes del
                    proceso de pago informarán de ello a Toperty, para que este
                    inicie las acciones que considere pertinentes contra el
                    Usuario. Si la controversia se resuelve a favor del Usuario,
                    la reversión se entenderá como definitiva.
                  </p>
                </li>
              </ol>
              <h5 className="bold">12. Sanciones.</h5>
              <p>
                La Página Web está disponible para el Usuario indefinidamente y
                sin limitación. Sin embargo, Toperty puede interrumpir, censurar
                y/o limitar el uso de la Página Web en cualquier momento sin
                previo aviso al Usuario, cuando tenga sospechas legítimas de mal
                uso o uso indebido o ilegal de la Página Web, provocando la
                remoción de toda la información y/o solicitud generada sin que
                haya lugar a ningún tipo de indemnización.
              </p>
              <h5 className="bold">
                13. Ley aplicable y resolución de controversias.
              </h5>
              <p>
                Estos Términos y Condiciones, cualquier asunto relacionado con
                ellos, incluyendo pero sin limitarse a su ejecución, así como
                los derechos y obligaciones en él contenidos, estarán sujetos a
                y deberán interpretarse de conformidad con las Leyes de la
                República de Colombia.
              </p>
              <p>
                Todas las controversias que se deriven del presente Contrato o
                que guarden relación con éste, serán resueltas definitivamente
                por un tribunal de arbitramento presentado ante el Centro de
                Arbitraje y Conciliación de la Cámara de Comercio de Bogotá, el
                cual estará sujeto a sus reglamentos y al procedimiento allí
                contemplado, de acuerdo con las siguientes reglas:
              </p>
              <ul>
                <li>
                  <p>
                    El tribunal estará integrado por tres (3) árbitros
                    designados por las partes de común acuerdo. En caso de que
                    no fuere posible, los árbitros serán designados por el
                    Centro de Arbitraje y Conciliación de la Cámara de Comercio
                    de Bogotá, a solicitud de cualquiera de las partes;
                  </p>
                </li>
                <li>
                  <p>El tribunal decidirá en derecho;</p>
                </li>
                <li>
                  <p>
                    El tribunal sesionará en las instalaciones del Centro de
                    Arbitraje y Conciliación de la Cámara de Comercio de Bogotá;
                  </p>
                </li>
                <li>
                  <p>
                    La secretaría del tribunal estará integrada por un miembro
                    de la lista oficial de secretarios del Centro de Arbitraje y
                    Conciliación de la Cámara de Comercio de Bogotá.
                  </p>
                </li>
              </ul>
              <h5 className="bold">14. Servicio al Usuario.</h5>
              <p>
                Toperty pone a disposición de sus Usuarios un servicio de ayuda,
                con el fin de contestar todas las peticiones, quejas o reclamos
                que éstos puedan tener y solucionar cualquier problema con los
                servicios prestados por Toperty.
              </p>
              <p>
                Los consumidores pueden hacer uso del servicio enviando un
                correo electrónico a la dirección:{" "}
                <a href="mailto:info@toperty.co">info@toperty.co</a>
              </p>
            </Col>
          </Row>
        </Col>
      </Container>
    </>
  );
}
