import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Alert from '../../Components/Alert';
import { LoadingModal, LoginModal } from '../../Components/ModalView';
import { ProfilePicture } from '../../Components/ProfileImage';
import { StoreContext } from '../../utils/store';
import { ProfileInteractions } from './ProfileInteractions';
import { Credentials } from './ProfileMultitab/Credentials';
import { PersonalInfo } from './ProfileMultitab/PersonalInfo';
import { RestorePassword } from './ProfileMultitab/RestorePassword';
import { Validations } from './ProfileValidations';

export function FormUpdateProfile() {
  const params = useParams();
  const { profile, setProfile } = useContext(StoreContext);
  const [page, setPage] = useState('personal');
  const [validate, setValidate] = useState(false);
  const [inputErrors, setInputErrors] = useState([]);
  const [passwordError, setPasswordError] = useState(false);
  const [dataChange, setDataChange] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [sentInvestorData, setSentInvestorData] = useState(false);
  const { setLoggedIn } = useContext(StoreContext);
  const tenantsEndpoint = '/tenants_api/tenants';
  const [showChangedProfile, setShowChangedProfile] = useState(false);

  useEffect(() => {
    if (params.registration_user) {
      if (params.registration_user === 'success') {
        setShowChangedProfile(true);
      } else {
        console.log('registration_user:');
        console.log(params.registration_user);
        async function getData() {
          await axios
            .get(
              tenantsEndpoint +
              '/confirm_signup?registration_user=' +
              params.registration_user
            )
            .then((response) => {
              setLoggedIn(true);
              localStorage.setItem('loggedIn', true);
              localStorage.setItem('profile', JSON.stringify(response.data));
              setProfile(response.data);
              setPage('forgot_password');
              return response.data;
            });
        }
        getData();
      }
    }
  }, [params]);

  const errorMessages = {
    name: 'Introduce un nombre válido.',
    email: 'Introduce una dirección de mail válida',
    phone:
      'Introduzca un número de teléfono válido con diez dígitos, si es un teléfono fijo recuerde agregar el indicativo nacional',
    document_type: 'Ingrese un tipo de documento válido',
    document_number: 'Ingrese un número de documento válido',
    password: 'Ingrese una contraseña válida'
  };

  const closeLoading = () => {
    setSendingData(false)
  }

  function handleSubmit() {
    setValidate(true);
  }

  function showPasswordError() {
    setSendingData(false);
    setPasswordError(true);
  }

  function handleSentData() {
    setSendingData(false);
    window.location.href = '/profile/success';
  }

  let successform = new ProfileInteractions({
    handleSentData: handleSentData,
    showPasswordError: showPasswordError,
  });

  const handleInvestorData = (k, v) => {
    setDataChange(true);
    if (v === '') {
      delete profile[k];
    } else {
      setProfile({
        ...profile,
        [k]: v,
      });
    }
  };

  useEffect(() => {
    if (validate) {
      setInputErrors([]);
      let errors = Validations(profile);
      setValidate(false);
      setInputErrors(errors);
      if (errors.length === 0) {
        setSendingData(true);
        setTimeout(() => {
          successform.updateProfile(profile, setSentInvestorData);
        }, 2000);
      }
    }
  }, [validate]);

  if (!profile) {
    return
  }
  return (
    <div className='profile-container'>
      {showChangedProfile && (
        <Alert alert={'Tu perfil se ha actualizado correctamente'} />
      )}
      <LoginModal />
      <LoadingModal
        show={sendingData}
        handleClose={closeLoading} />

      <ProfilePicture />
      <div className='picture-info'>
        <p className='picture-name'>{profile['name']}</p>
        <p className='picture-email'>{profile['email']}</p>
      </div>
      <div className='profile-card'>
        <div className='profile-slider'>
          <button
            className={page === 'personal' ? 'active' : ''}
            onClick={() => setPage('personal')}>
            Datos personales
          </button>
          <button
            className={
              page === 'credentials' || page === 'forgot_password'
                ? 'active'
                : ''
            }
            onClick={() => setPage('credentials')}>
            Credenciales
          </button>
        </div>
        <div className='profile-content-container'>
          {page === 'personal' ? (
            <PersonalInfo
              investorData={profile}
              handleInvestorData={handleInvestorData}
              inputErrors={inputErrors}
              errorMessages={errorMessages}
            />
          ) : page === 'forgot_password' ? (
            <RestorePassword
              investorData={profile}
              handleInvestorData={handleInvestorData}
              inputErrors={inputErrors}
              errorMessages={errorMessages}
            />
          ) : (
            <Credentials
              investorData={profile}
              handleInvestorData={handleInvestorData}
              inputErrors={inputErrors}
              errorMessages={errorMessages}
            />
          )}

          {passwordError && (
            <p className='error-msg'>
              Tienes que ingresar tu contraseña actual correctamente para
              asignar una nueva contraseña tu cuenta.
            </p>
          )}

          {page !== 'forgot_password' ? (
            <button
              className='btn-rounded-green'
              id='NextBtn'
              onClick={handleSubmit}
              disabled={dataChange === false}>
              {'Guardar'}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
