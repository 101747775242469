import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer";
import { Navbarcomp as Navbar } from "./Components/Navbarcomp";
import { About } from "./Pages/About";
import { FaqPage } from "./Pages/FAQ/FaqPage";
import { Landing } from "./Pages/Landing";
import NotFound from "./Pages/NotFound";
import { PersonalData } from "./Pages/PersonalData";
import { FormUpdateProfile } from "./Pages/Profile/FormUpdateProfile";
import { Success } from "./Pages/Success";
import { Program } from "./Pages/TenantsProgram/Program";
import { ProgramOpportunities } from "./Pages/TenantsProgram/ProgramOpportunities";
import { Tyc } from "./Pages/Tyc";
import "./Styles/styles.scss";
import { StoreContext, StoreProvider } from "./utils/store";

ReactGA.initialize("G-XTW9PWX0XD");

function App() {
  const [successpage, setSuccesspage] = useState(false);
  const [nonavpage, setNonavpage] = useState(false);
  const [bgfooter, setBgfooter] = useState(" ");
  const [pathname, setPathname] = useState("/");
  const [scroll, setScroll] = useState(0);
  const [transparent, setTransparent] = useState(false);
  const { loggedIn } = useContext(StoreContext);

  useEffect(() => {
    const handlescroll = (event) => {
      setScroll(window.scrollY);
      scroll <= window.innerHeight - 200 && pathname === "/"
        ? setTransparent(true)
        : setTransparent(false);
    };
    window.addEventListener("scroll", handlescroll);
    return () => {
      window.removeEventListener("scroll", handlescroll);
    };
  }, [pathname, scroll]);

  window.onload = function () {
    let pathname = window.location.pathname;
    setPathname(pathname);
    pathname === "/" && setTransparent(true);

    pathname.indexOf("/form") === 0 || pathname === "/login"
      ? setNonavpage(true)
      : setNonavpage(false);
    window.location.pathname === "/success"
      ? setSuccesspage(true)
      : setSuccesspage(false);
  };
  return (
    <StoreProvider>
      <Navbar
        success={successpage ? "successpage" : "normalpage"}
        nonav={nonavpage ? "nonavpage" : "normalpage"}
        pathname={pathname}
        transparent={transparent}
      />
      <a
        className="whatsapp"
        href="https://wa.me/message/S26MKBHPBLFHG1?src=qr"
        rel="noreferrer"
        target="_blank"
      >
        <div className="icon"></div>
      </a>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route
          path="/opportunities/:registration_user"
          element={<ProgramOpportunities />}
        />
        <Route
          path="/opportunities/"
          element={loggedIn ? <ProgramOpportunities /> : <Landing />}
        />
        <Route
          path="/program/:id"
          element={loggedIn ? <Program /> : <Landing />}
        />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/tyc" element={<Tyc />} />
        <Route path="/personal-data" element={<PersonalData />} />
        <Route path="/success" element={<Success />} />
        <Route path="/about" element={<About />} />
        <Route path="/profile" element={<FormUpdateProfile />} />
        <Route
          path="/profile/:registration_user"
          element={<FormUpdateProfile />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer nofoot={nonavpage ? "nofootpage" : "normalpage"} bg={bgfooter} />
    </StoreProvider>
  );
}

export default App;
