import { React, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { LoginModal as Modal } from '../Components/ModalView';


export function Landing() {
  const [showLogin, setShowLogin] = useState(true);
  const closeLoginModal = () => {
    setShowLogin(false);
    window.location.reload();
  };
  return (
    <Container fluid className="p-sm-3 success-container">
      <Row className="align-items-center text-center justify-content-center">
        <Col md={6}>
          <Modal
            show={showLogin}
            handleClose={closeLoginModal}
          />
        </Col>
      </Row>
    </Container>
  );
}
