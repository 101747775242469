import React from 'react';
import { Modal } from 'react-bootstrap';
import { Login } from '../Pages/Login/Login';

export function LoginModal(props) {
  const show = props.show;
  const handleClose = props.handleClose;
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className='login-modal'>
      <Modal.Body>
        <Login
          handleClose={handleClose}
        />

      </Modal.Body>
    </Modal>
  );
}

export function NotificationModal(props) {
  const show = props.show;
  const handleClose = props.handleClose;
  const message = props.message;

  return (
    <Modal
      show={show}
      onHide={handleClose}>
      <Modal.Header closeButton>{/* TODO */}</Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>{/* TODO */}</Modal.Footer>
    </Modal>
  );
}

export function LoadingModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className='loading-modal'
      centered>
      <Modal.Body>
        <h4>un momento por favor</h4>
        <h3>Actualizando Perfil</h3>
        <div className='lds-ellipsis'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
