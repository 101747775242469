import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FinancialCard } from "../../Components/FinancialCard";
import IncomesBarChart from "../../Components/ProgramIncomesChart";
import MarketValueChart from "../../Components/MarketValueChart";
import DonutChart from "../../Components/RemainingMonthsChart";
import { colors, monthsDict } from "../../utils/colorsPallete";
import { StoreContext } from "../../utils/store";

function GetMonthsElapsed(date) {
  // Check if the input date is valid
  if (isNaN(Date.parse(date))) {
    console.error("Invalid date provided.");
    return null;
  }

  const current_date = new Date();
  const lastDate = new Date(date);

  // Calculate the difference in months
  let months = (current_date.getFullYear() - lastDate.getFullYear()) * 12;
  months -= lastDate.getMonth();
  months += current_date.getMonth();

  // Adjust if the current day is earlier than the lastDate's day
  if (current_date.getDate() < lastDate.getDate()) {
    months--;
  }

  // Start counting from 1 for the first month
  return months + 1;
}

function prepareFinancialSerie(serie, type) {
  let financialSerie = Array.from({ length: 12 }, (_) => null);
  for (let i = 0; i < serie.length; i++) {
    financialSerie[serie[i]["month"] - 1] = serie[i][type];
  }
  return financialSerie;
}

export function Program() {
  const SummaryEndpoint = "/tenants_api/tenants/payments";
  const EstimatedReturnsEndpoint = "/tenants_api/tenants/details";
  const AvailableYearsEndpoint = "/tenants_api/tenants/available_years";
  const inflationRatesEndpoint = "/tenants_api/tenants/market_value_change";
  const keyProgramDataEndpoint = "/tenants_api/tenants/key_program_data";
  const params = useParams();
  const [summaryData, setSummaryData] = useState(null);
  const [incomesChartData, setIncomesChartData] = useState({
    ChartLabels: null,
    values: null,
    associatedColors: null,
  });
  const [availableYears, setAvailableYears] = useState(null);
  const [keyProgramData, setKeyProgramData] = useState(null);
  const [enabledArrows, setEnabledArrows] = useState({
    right_arrow: false,
    left_arrow: true,
  });
  const [enabledSeriesArrows, setEnabledSeriesArrows] = useState({
    right_arrow: false,
    left_arrow: true,
  });
  const [financialSeries, setFinancialSeries] = useState(null);
  const { profile } = useContext(StoreContext);

  const [cardsData, setCardsData] = useState({
    DividendsReceived: 0,
    EstimatedReturn: 0,
  });
  const [yearData, setYearData] = useState(null);
  const [seriesYearData, setSeriesYearData] = useState(null);
  let COPLocale = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumFractionDigits: 0,
  });

  const hasYear = (value) => {
    return availableYears.some((item) => value === item);
  };
  const increaseYear = () => {
    if (hasYear(yearData + 1)) {
      setYearData(yearData + 1);
    }
  };
  const decreaseYear = () => {
    if (hasYear(yearData - 1)) {
      setYearData(yearData - 1);
    }
  };
  const increaseSeriesYear = () => {
    if (hasYear(seriesYearData + 1)) {
      setSeriesYearData(seriesYearData + 1);
    }
  };
  const decreaseSeriesYear = () => {
    if (hasYear(seriesYearData - 1)) {
      setSeriesYearData(seriesYearData - 1);
    }
  };
  const GenerateChartData = (idx, associatedColors) => {
    let SerieValues = [];
    for (const [key, values] of Object.entries(summaryData)) {
      if (key !== "date") {
        SerieValues.push({
          value: values[summaryData["date"].indexOf(idx)],
          color: associatedColors[key],
        });
      }
    }

    return SerieValues;
  };

  useEffect(() => {
    if (summaryData) {
      let associatedColors = {};
      let idx = 0;
      let data = [];
      let emptyMonths = [];
      for (const key of Object.keys(summaryData)) {
        if (key !== "date") {
          associatedColors[key] = colors[idx];
          idx += 1;
        }
      }
      Object.keys(summaryData).forEach((element, index) => {
        if (element !== "date") {
          emptyMonths.push({ value: 0, color: colors[index] });
        }
      });

      for (let i = 1; i <= Object.keys(monthsDict).length; i++) {
        let processedData = {};
        if (summaryData["date"].includes(i)) {
          processedData = {
            name: i,
            values: GenerateChartData(i, associatedColors),
          };
        } else {
          processedData = {
            name: i,
            values: emptyMonths,
          };
        }
        data.push(processedData);
      }
      setIncomesChartData({
        ...incomesChartData,
        ["associatedColors"]: associatedColors,
        ["values"]: data,
        ["ChartLabels"]: summaryData["date"],
      });
    }
  }, [summaryData]);

  useEffect(() => {
    const GetAvailableYears = async (id) => {
      axios
        .get(AvailableYearsEndpoint + "?id=" + id)
        .then((response) => {
          setAvailableYears(response.data["response"]);
          setYearData(Math.max(...response.data["response"]));
          setSeriesYearData(Math.max(...response.data["response"]));
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const GetEstimatedReturn = async (id) => {
      await axios
        .get(EstimatedReturnsEndpoint + "?id=" + id)
        .then((response) => {
          setCardsData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (parseInt(params.id) !== 5 && profile.id) {
      GetAvailableYears(profile.id);
      GetEstimatedReturn(profile.id);
    } else if (parseInt(params.id) === 5 && profile.id === 3) {
      GetAvailableYears(2);
      GetEstimatedReturn(2);
    }
  }, [profile.id]);
  useEffect(() => {
    const GetSummarySectionData = async (id) => {
      await axios
        .get(SummaryEndpoint + "?id=" + id + "&year=" + yearData)
        .then((response) => {
          setSummaryData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (parseInt(params.id) !== 5 && profile.id) {
      GetSummarySectionData(profile.id);
    } else if (parseInt(params.id) === 5 && profile.id === 3) {
      GetSummarySectionData(2);
    }
  }, [yearData, profile.id]);

  useEffect(() => {
    if (availableYears) {
      setEnabledArrows({
        ...enabledArrows,
        ["right_arrow"]: hasYear(yearData + 1),
        ["left_arrow"]: hasYear(yearData - 1),
      });
    }
  }, [yearData, availableYears]);

  useEffect(() => {
    if (availableYears) {
      setEnabledSeriesArrows({
        ...enabledSeriesArrows,
        ["right_arrow"]: hasYear(seriesYearData + 1),
        ["left_arrow"]: hasYear(seriesYearData - 1),
      });
    }
  }, [seriesYearData, availableYears]);

  useEffect(() => {
    const GetFinancialSeries = async () => {
      await axios
        .get(
          inflationRatesEndpoint +
            "?year=" +
            seriesYearData +
            "&prop_id=" +
            cardsData.prop_id
        )
        .then((response) => {
          if (response.data["market_value_changes"]) {
            setFinancialSeries({
              MarketValueSerie: prepareFinancialSerie(
                response.data["market_value_changes"],
                "average_value"
              ),
              OwnershipPercentage: prepareFinancialSerie(
                response.data["ownership_percentage"],
                "cumulative_ownership_percentage"
              ),
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const GetKeyProgramData = async () => {
      await axios
        .get(keyProgramDataEndpoint + "?prop_id=" + cardsData.prop_id)
        .then((response) => {
          setKeyProgramData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (cardsData["DividendsReceived"] != 0) {
      GetFinancialSeries();
      GetKeyProgramData();
    }
  }, [cardsData, seriesYearData]);

  if (!profile) {
    return;
  }

  return (
    <div className="program-container">
      {incomesChartData["ChartLabels"] && incomesChartData["values"] && (
        <>
          <h4 className="page-title">{profile["name"]}</h4>
          <p className="program-section-title">
            {cardsData["investment_name"]}
          </p>

          <section>
            <p className="chart-title">Avance programa</p>
            {cardsData["last_payment_date"] && (
              <p className="program-section-subtitle">
                Última fecha de actualización:{" "}
                {cardsData["last_payment_date"].replace(/-/gi, "/")}
              </p>
            )}
            <p className="incomes-chart-title">{yearData}</p>
            <p className="incomes-chart-subtitle mb-3">
              (cifras en millones de pesos)
            </p>
            <IncomesBarChart
              title="año"
              data={incomesChartData["values"]}
              labels={incomesChartData["ChartLabels"]}
              associatedColors={incomesChartData["associatedColors"]}
              enabledArrows={enabledArrows}
              decreaseYear={decreaseYear}
              increaseYear={increaseYear}
            />
          </section>
        </>
      )}
      <>
        <p className="section-title mt-20">Compra de vivienda</p>
        {summaryData && (
          <div className="portfolio-card-container">
            <FinancialCard
              key="Propiedad adquirida"
              title="Propiedad adquirida"
              tooltip=""
              value={(cardsData["ownership_percentage"] * 100).toFixed(2) + "%"}
            />
            <FinancialCard
              key="Abonos a compra parcial"
              title="Abonos a compra parcial"
              tooltip=""
              value={COPLocale.format(cardsData["total_principal_payments"])}
            />
            <FinancialCard
              key="Dividendos de arriendo recibidos"
              title="Dividendos de arriendo recibidos"
              tooltip=""
              value={COPLocale.format(cardsData["total_rent_returns"])}
            />
            <FinancialCard
              key="Aportes totales realizados"
              title="Aportes totales realizados"
              tooltip=""
              value={COPLocale.format(
                cardsData["total_rent_returns"] +
                  cardsData["total_principal_payments"]
              )}
            />
          </div>
        )}
      </>
      <section>
        <p className="section-title mt-20" id="sales-section">
          Progreso del programa
        </p>
        <p className="program-section-subtitle">
          Objetivo de adquisición:{" "}
          {(cardsData["target_ownership_percentage"] * 100).toFixed(1)}%
        </p>
        <div className="sales-card">
          <div className="donut-chart-container">
            <DonutChart
              percentFilled={
                cardsData
                  ? (cardsData["completion_percentage"] * 100).toFixed(1)
                  : 10
              }
              filledColor={
                cardsData ? "rgba(40, 5, 65, 1)" : "rgba(185, 185, 185, 1)"
              }
              startAngle={cardsData ? -90 : 45}
              textContent={
                cardsData
                  ? (cardsData["completion_percentage"] * 100).toFixed(1)
                  : 0
              }
            />
          </div>
          <div className="sales-data-container">
            <p className="title p4">
              Porcentaje adquirido respecto al objetivo de adquisición.
            </p>
          </div>
        </div>
      </section>
      {financialSeries && keyProgramData && (
        <>
          <p className="section-title mt-20">Valor de la vivienda</p>
          <div className="portfolio-card-container">
            <FinancialCard
              key="Valor inicial del inmueble"
              title="Valor inicial del inmueble"
              tooltip=""
              value={COPLocale.format(cardsData["market_value"])}
            />
            <FinancialCard
              key="Valor inmueble a la fecha"
              title="Valor inmueble a la fecha"
              tooltip=""
              value={COPLocale.format(
                keyProgramData["last_market_value"]
                  ? keyProgramData["last_market_value"]
                  : cardsData["market_value"]
              )}
            />
            <FinancialCard
              key="Meses transcurridos"
              title="Meses transcurridos"
              tooltip=""
              value={
                "first_payment_date" in keyProgramData
                  ? GetMonthsElapsed(keyProgramData["first_payment_date"])
                  : "No disponible"
              }
            />
            <FinancialCard
              key="Indexación a aplicar"
              title="Indexación a aplicar"
              tooltip=""
              value={
                0.45 < keyProgramData["last_inflation_rate"] * 100
                  ? (keyProgramData["last_inflation_rate"] * 100).toFixed(2) +
                    "%"
                  : params.id == 1
                  ? "0.41%"
                  : "0.45%"
              }
            />
            <FinancialCard
              key="Propiedad por comprar"
              title="Propiedad por comprar"
              tooltip=""
              value={
                "current_unsold_property_value" in keyProgramData
                  ? COPLocale.format(
                      keyProgramData["current_unsold_property_value"]
                    )
                  : "No disponible"
              }
            />
            <FinancialCard
              key="Última inflación DANE"
              title="Última inflación DANE"
              tooltip="Click aquí para ver el reporte del DANE"
              href="https://www.dane.gov.co/index.php/estadisticas-por-tema/precios-y-costos/indice-de-precios-al-consumidor-ipc/ipc-informacion-tecnica"
              target="_blank"
              value={
                (keyProgramData["last_inflation_rate"] * 100).toFixed(2) + "%"
              }
            />
          </div>
        </>
      )}
      <>
        {financialSeries && (
          <section>
            <MarketValueChart
              year={seriesYearData}
              marketValueSerie={financialSeries["MarketValueSerie"]}
              ownershipSerie={financialSeries["OwnershipPercentage"]}
              enabledArrows={enabledSeriesArrows}
              decreaseYear={decreaseSeriesYear}
              increaseYear={increaseSeriesYear}
            />
          </section>
        )}
      </>
    </div>
  );
}
