import axios from "axios";
import React from "react";

export class loginInteractions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.tenantsEndpoint = "/tenants_api/tenants";
		this.showLogin = props.showLogin;
		this.confirmLogin = props.confirmLogin;
		this.showLoginError = props.showLoginError;
		this.finishForgotPassword = props.finishForgotPassword;
		this.showLoginAPIError = props.showLoginAPIError;

	}

	async login(email, password) {
		let searchUrl = `${this.tenantsEndpoint}/login?email=
			${email}&password=${encodeURIComponent(password)}`;
		var success = null;

		try {
			success = await (axios.get(searchUrl)).then((response) => {
				success = true;
				this.confirmLogin(response.data);
				return response.data;
			});
		} catch (error) {

			if (error.response) {
				success = error.response.status;
			}
			else {
				success = 500;
			}

		}
		if (success === 401) {
			this.showLoginError();
		} else if (success === 409) {
			this.showLoginConfirmationError();
		} else if (success === 500) {
			this.showLoginAPIError()
		}
	}


	async forgotPassword(email) {
		let searchUrl = `${this.tenantsEndpoint}/forgot_password?email=
			${email}`;
		var success = null;
		try {
			success = await (axios.get(searchUrl)).then((response) => {
				this.finishForgotPassword();
				return response.data;
			})
		}
		catch (error) {
			if (error.response) {
				// Request made and server responded
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
			} else if (error.request) {
				// The request was made but no response was received
				console.log(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			}
		};
		if (success === null) {
			//TO-DO'
		}
	}

}
