import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Card } from "../../Components/Card";
import { StoreContext } from "../../utils/store";
import { useParams } from "react-router-dom";

export function ProgramOpportunities() {
  const AssociatedPropertyEndpoint = "/tenants_api/tenants/details";
  const TenantsEndpoint = "/tenants_api/tenants/";
  const keyProgramDataEndpoint = "/tenants_api/tenants/key_program_data";
  const params = useParams();
  const [propertyData, setPropertyData] = useState({});
  const [keyProgramData, setKeyProgramData] = useState({});
  const { loggedIn, setLoggedIn } = useContext(StoreContext);
  const { profile, setProfile } = useContext(StoreContext);

  useEffect(() => {
    const GetEstimatedReturn = async (id) => {
      await axios
        .get(AssociatedPropertyEndpoint + "?id=" + id)
        .then((response) => {
          setPropertyData((prevState) => ({
            ...prevState,
            [response.data.prop_id]: response.data,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (profile?.id) {
      GetEstimatedReturn(profile.id);
    }
    if (profile?.id === 3) {
      GetEstimatedReturn(2);
    }
  }, [profile]);

  useEffect(() => {
    if (params.registration_user) {
      async function getData() {
        await axios
          .get(
            TenantsEndpoint +
              "/confirm_signup?registration_user=" +
              params.registration_user
          )
          .then((response) => {
            setLoggedIn(true);
            localStorage.setItem("loggedIn", true);
            localStorage.setItem("profile", JSON.stringify(response.data));
            setProfile(response.data);
            return response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      getData();
    }
  }, [params]);

  useEffect(() => {
    const GetKeyProgramData = async (prop_id) => {
      await axios
        .get(keyProgramDataEndpoint + "?prop_id=" + prop_id)
        .then((response) => {
          setKeyProgramData((prevState) => ({
            ...prevState,
            [prop_id]: response.data,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (propertyData) {
      Object.keys(propertyData).forEach((prop_id) => {
        GetKeyProgramData(prop_id);
      });
    }
  }, [propertyData]);

  return (
    <div className="props-container">
      {propertyData &&
        Object.keys(propertyData).map((id) => {
          return <Card data={propertyData[id]} keyData={keyProgramData[id]} />;
        })}
    </div>
  );
}
