import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Faq } from "./Faq";

export function FaqPage() {
  return (
    <>
      <Container className="p-0 overflow-hidden" fluid>
        <section className="divider mb-5 bg-light-blue">
          <Row className="">
            <Col md={12} className=" py-5 white">
              <Row className="justify-content-center">
                <Col md={4} sm={12} className="text-center">
                  <h4 className="bolder mb-4 white">
                    Revisa las preguntas frecuentes para solucionar las dudas
                    que tengas.
                  </h4>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <Row className="justify-content-center my-5">
          <Col md={8}>
            <Faq />
          </Col>
        </Row>
      </Container>
    </>
  );
}
