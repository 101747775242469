import React, { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import { StoreContext } from '../../utils/store';
import { loginInteractions } from './LoginInteractions';
import { emailValidation } from './LoginValidations';
import { PasswordForgotten } from './PasswordForgotten';

export function Login(props) {
  const { setLoggedIn } = useContext(StoreContext);
  const [investorData, setInvestorData] = useState({});
  const [emailError, setEmailError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginAPIError, setLoginAPIError] = useState(false);
  const [state, setState] = useState('login');
  const { profile, setProfile } = useContext(StoreContext);

  const handleFormData = (k, v) => {
    setInvestorData({
      ...investorData,
      [k]: v,
    });
  };

  function showLogin() {
    setState('login');
  }

  function confirmLogin(investorData) {
    setLoggedIn(true);
    localStorage.setItem('loggedIn', true);
    setProfile(investorData);
    localStorage.setItem('profile', JSON.stringify(investorData));
    props.handleClose();
    window.location.href = "/opportunities"
  }

  function showLoginError() {
    setLoginError(true);
  }

  function showLoginAPIError() {
    setLoginAPIError(true);
  }

  function showForgotPassword(e) {
    e.preventDefault();
    setState('forgot_password');
  }

  function finishForgotPassword() {
    setState('forgotten_password');
  }

  let interactions = new loginInteractions({
    showLogin: showLogin,
    confirmLogin: confirmLogin,
    showLoginError: showLoginError,
    showLoginAPIError: showLoginAPIError,
    finishForgotPassword: finishForgotPassword,
  });

  async function handleLogin(e) {
    e.preventDefault();
    setLoginError(false);
    if (!emailValidation(investorData['email'])) {
      setEmailError(true);
    } else {
      setEmailError(false);
      await interactions.login(investorData['email'], investorData['password']);
    }
  }

  async function handleForgotPassword(e) {
    e.preventDefault();
    await interactions.forgotPassword(investorData['email']);
  }

  return (
    <div className='login-container'>
      <div className='login-form'>
        {state === 'login' && <h4 className='title'>Inicia sesión</h4>}
        {state === 'forgot_password' && (
          <h4 className='title'>Reestablecer contraseña</h4>
        )}

        <Form>
          <Form.Group controlId='correo'>
            {state !== "forgotten_password" && state !== "forgot_password" && (<Form.Control
              type='text'
              name=''
              className='rounded-input mt-2'
              placeholder='Email'
              onChange={(e) => handleFormData('email', e.target.value)}
              disabled={state !== "login"}
            />)}
            {emailError && (
              <div className='error-msg'>
                <p>No has ingresado una dirección de correo válida</p>
              </div>
            )}
          </Form.Group>

          {state === 'login' && (
            <div>
              <Form.Group
                className='mb-3'
                controlId='contraseña'>
                <Form.Control
                  type='password'
                  name=''
                  className='rounded-input my-3'
                  placeholder='Contraseña'
                  autoComplete='off'
                  onChange={(e) => handleFormData('password', e.target.value)}
                />
              </Form.Group>
              {loginError && (
                <div className='error-msg'>
                  <p>
                    La contraseña ingresada no es correcta, por favor ingrésala
                    de nuevo
                  </p>
                </div>
              )}

              {loginAPIError && (
                <div className='error-msg'>
                  <p>
                    Algo ha fallado con nuestro sistema, inténtalo de nuevo en
                    unas horas.
                  </p>
                </div>
              )}

              <button
                className='btn-transparent-end w-100 py-3 mt-1'
                onClick={showForgotPassword}>
                ¿Olvidaste tu contraseña?
              </button>
              <button
                className='btn-rounded-green w-100 py-3'
                onClick={handleLogin}>
                Ingresa a tu cuenta
              </button>
            </div>
          )}

          {state === 'forgot_password' && (
            <div>
              <Form.Group
                className='mb-3'
                controlId='email'>
                <Form.Control
                  type='text'
                  name=''
                  className='rounded-input my-3'
                  placeholder='Email'
                  onChange={(e) => handleFormData('email', e.target.value)}
                />
              </Form.Group>
              <button
                className='btn-rounded-green w-100 py-3'
                onClick={handleForgotPassword}>
                Enviar
              </button>
            </div>
          )}
          {state === 'forgotten_password' && (
            <PasswordForgotten handleClose={props.handleClose} />
          )}
        </Form>
      </div>
    </div>
  );
}
