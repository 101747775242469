import { React, useContext } from "react";
import { StoreContext } from "../utils/store";

function GetMonthsElapsed(date) {
  // Check if the input date is valid
  if (isNaN(Date.parse(date))) {
    console.error("Invalid date provided.");
    return null;
  }

  const current_date = new Date();
  const lastDate = new Date(date);

  // Calculate the difference in months
  let months = (current_date.getFullYear() - lastDate.getFullYear()) * 12;
  months -= lastDate.getMonth();
  months += current_date.getMonth();

  // Adjust if the current day is earlier than the lastDate's day
  if (current_date.getDate() < lastDate.getDate()) {
    months--;
  }

  // Start counting from 1 for the first month
  return months + 1;
}

export function Card(props) {
  let COPLocale = Intl.NumberFormat("es-CO");
  let link = "/program/" + props.data.prop_id;
  const { loggedIn, setShowLogin } = useContext(StoreContext);

  const percentage = props.data["completion_percentage"] * 100;

  return (
    <>
      <a
        href={link}
        onClick={(e) => {
          if (!loggedIn) {
            e.preventDefault();
            setShowLogin(true);
          }
        }}
        className="prop-link Abierta"
      >
        <div className={`invest-card Abierta`}>
          <div className="prop-img">
            <img
              src={`https://toperty-investments.s3.amazonaws.com/properties/${props.data.prop_id}/1.jpg`}
              alt="imagen"
            />
          </div>
          {props.data && (
            <>
              <div className="prop-title">
                <h5 className="bold p3 ">{props.data["investment_name"]}</h5>
                <p className="p6">{props.data["address"]}</p>
              </div>
            </>
          )}

          <div className="prop-info">
            {props.data && (
              <>
                <>
                  <div>
                    <p className="subtitle p6">Propiedad adquirida</p>
                  </div>
                  <div className="">
                    <h5 className="text-end p3">
                      {(props.data["ownership_percentage"] * 100).toFixed(2)} %
                    </h5>
                  </div>
                </>

                {props.keyData && (
                  <>
                    <div className="">
                      <p className="subtitle p6">Tiempo transcurrido</p>
                    </div>
                    <div className="">
                      <h5 className="text-end p3">
                        {GetMonthsElapsed(props.keyData["first_payment_date"])}{" "}
                        {GetMonthsElapsed(props.keyData["first_payment_date"]) >
                        1
                          ? " meses"
                          : " mes"}
                      </h5>
                    </div>
                  </>
                )}

                <div className="">
                  <p className="subtitle p6">Aportes totales</p>
                </div>
                <div className="">
                  <h5 className="text-end p3">
                    $
                    {COPLocale.format(
                      props.data["total_principal_contribution"].toFixed(0)
                    )}
                  </h5>
                </div>
              </>
            )}
          </div>
          {props.data && (
            <div className="prop-footer">
              <div className="progress-bar sm">
                <div
                  className="progress"
                  style={{
                    width: percentage > 0 ? percentage + "%" : "1em",
                  }}
                ></div>
              </div>
              <div className="justify-content-end progress-info">
                <p>Progreso del programa: {percentage.toFixed(1) + "%"}</p>
              </div>
            </div>
          )}
          {props.completionPercentage && (
            <div className="prop-footer">
              <div className="progress-bar sm">
                <div
                  className="progress"
                  style={{
                    width:
                      props.completionPercentage > 0
                        ? props.completionPercentage + "%"
                        : "1em",
                  }}
                ></div>
              </div>
              <div className="justify-content-around progress-info">
                <p>{props.completionPercentage.toFixed(1) + "%"}</p>
                <p>{props.investmentDuration} meses</p>
              </div>
            </div>
          )}
          <button
            type="button"
            className="btn-rounded-gray"
            onClick={(e) => {
              if (!loggedIn) {
                e.preventDefault();
                setShowLogin(true);
              } else {
                e.preventDefault();
                window.location.href = link;
              }
            }}
          >
            {window.location.href.includes("/portfolio")
              ? "Ver inversión"
              : "Ver detalle"}
          </button>
        </div>
      </a>
    </>
  );
}
