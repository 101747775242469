import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { monthsDict } from "../utils/colorsPallete";

const IncomesBarChart = (props) => {
  const LegendDict = {
    rent: "Pago arriendo",
    principal: "Abono a compra parcial",
    additional: "Abonos extraordinarios",
    recoveries: "Gastos Recobros",
    costs: "Costos del apartamento",
  };
  const data = props.data;
  const associatedColors = props.associatedColors;
  const [activeIndex, setActiveIndex] = useState(null);
  let COPLocale = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumFractionDigits: 0,
  });

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const totalValues = data.reduce((acc, curr) => {
    return acc.map((val, i) => val + curr.values[i].value);
  }, new Array(data[0].values.length).fill(0));

  const getMaxValue = () => {
    return Math.max(...totalValues);
  };

  const getBarStyle = (valueObj, index) => {
    const height = props.availableData
      ? (valueObj.value / getMaxValue()) * 100 * 1.5
      : (valueObj.value / getMaxValue()) * 100 * 1.8;
    const top = 100 - height;
    const backgroundColor = valueObj.color;
    const opacity = activeIndex === null || activeIndex === index ? 1 : 0.5;

    return {
      height: `${height}%`,
      top: `${top}%`,
      backgroundColor,
      opacity,
    };
  };

  return (
    <div className={`stacked-bar-chart ${props.type}`}>
      <button
        className="chart-control prev"
        disabled={!props.enabledArrows["left_arrow"]}
        onClick={props.decreaseYear}
      >
        <div className="icon"></div>
      </button>
      <div className="chart-container">
        {data.map((series, seriesIndex) => (
          <>
            <div key={series.name} className="series-container">
              <p className="bold">
                {(
                  series.values.reduce(function (prev, current) {
                    return prev + +current.value;
                  }, 0) / 1000000
                ).toFixed(3) != 0.0
                  ? (
                      series.values.reduce(function (prev, current) {
                        return prev + +current.value;
                      }, 0) / 1000000
                    ).toFixed(3)
                  : ""}{" "}
              </p>

              {series.values.map((value, index) => (
                <Tooltip title={COPLocale.format(value["value"])} key={index}>
                  <div
                    key={`${seriesIndex}-${index}`}
                    className="bar"
                    style={getBarStyle(
                      value,
                      seriesIndex * series.values.length + index
                    )}
                    onMouseEnter={() =>
                      handleMouseEnter(
                        seriesIndex * series.values.length + index
                      )
                    }
                    onMouseLeave={handleMouseLeave}
                  ></div>
                </Tooltip>
              ))}
            </div>
          </>
        ))}
      </div>
      <button
        className="chart-control"
        disabled={!props.enabledArrows["right_arrow"]}
        onClick={props.increaseYear}
      >
        <div className="icon"></div>
      </button>
      <div className="xtics-container">
        {data.map((value, index) => (
          <div key={index} className="xtic">
            {monthsDict[value.name]}
          </div>
        ))}
      </div>
      <div className="legend-container mt-3">
        {Object.keys(associatedColors).map((property) => (
          <div key={property} className="legend">
            <span
              className="legend-color"
              style={{ backgroundColor: associatedColors[property] }}
            ></span>
            {LegendDict[property]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default IncomesBarChart;
